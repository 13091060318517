import { useState, useEffect } from 'react';
import { message } from 'chatInterface';

export function useSendMessage(
  chatType: string,
  setIsLoading: (loading: boolean) => void
) {
  const [messages, setMessages] = useState<message[]>([]);
  const [load, setLoad] = useState<boolean>(false);
  // AudioContext 초기화
  const audioContext = new window.AudioContext();

  // 오디오 재생 함수 (Web Audio API 사용)
  const playSound = async (audioFile: string) => {
    try {
      const response = await fetch(audioFile);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      source.start();
    } catch (error) {
      console.error('오디오 재생 오류:', error);
    }
  };

  // 효과음 파일 경로
  const sendSoundFile = '/MP_Pling.mp3';
  const receiveSoundFile = '/MP_간결한 메시지 도착.mp3';

  // 메일인 경우 초기화
  useEffect(() => {
    if (chatType === 'mail') {
      setMessages([
        {
          id: 0,
          text: `**안녕하세요! 비즈니스 메일 작성 도우미입니다.**

저는 여러분이 전문적이고 효과적인 비즈니스 메일을 작성하는 데 도움을 드리기 위해 여기에 있습니다. 아래의 각 항목에 대한 정보를 차례대로 입력해주시면, 적절한 메일 초안과 메일을 보낼 수 있는 링크를 제공해드리겠습니다.

1. **메일의 목적**:
- 예: 회의 요청, 제품 문의, 계약 협상, 고객 감사 등

2. **수신자 정보**:
- 이름:
- 직책:
- 회사명:
- 이메일 주소:

3. **발신자 정보**:
- 이름:
- 직책:
- 회사명:

4. **메일 본문에 포함할 주요 내용**:
- 예: 회의 일정 제안, 제품 사양 요청, 계약 조건 논의 등

5. **추가 정보 또는 특별 요청사항**:
- 예: 특정 날짜까지 회신 요청, 첨부 파일 설명 등

6. **서명 정보**:
- 이름:
- 직책:
- 연락처:
- 회사명:

---

각 항목별로 필요한 정보를 입력해주세요!

### 1단계
1) 메일의 목적은 무엇인가요?

### 2단계
2) 수신자의 정보를 알려주세요.
- 이름:
- 직책:
- 회사명:
- 이메일 주소:

### 3단계
3) 발신자의 정보를 알려주세요.
- 이름:
- 직책:
- 회사명:

### 4단계
4) 메일 본문에 포함할 주요 내용을 알려주세요.

### 5단계
5) 추가 정보 또는 특별 요청사항이 있나요?

### 6단계
6) 서명을 위한 발신자의 세부정보를 알려주세요.
- 이름:
- 직책:
- 연락처:
- 회사명:

---

**예시 입력:**

1. **메일의 목적**: 회의 요청
2. **수신자 정보**:
- 이름: 김철수
- 직책: 마케팅 이사
- 회사명: ABC 주식회사
- 이메일 주소: chulsoo.kim@abc.com
3. **발신자 정보**:
- 이름: 이영희
- 직책: 영업 팀장
- 회사명: XYZ 주식회사
4. **메일 본문에 포함할 주요 내용**: 다음 주 수요일 오전 10시에 제품 시연 회의를 요청합니다.
5. **추가 정보 또는 특별 요청사항**: 회신은 금요일까지 부탁드립니다.
6. **서명 정보**:
- 이름: 이영희
- 직책: 영업 팀장
- 연락처: 010-1234-5678
- 회사명: XYZ 주식회사

---

**생성된 메일 초안:**

---

**제목: 제품 시연 회의 요청**

안녕하세요, 김철수 이사님.

저는 XYZ 주식회사 영업 팀장 이영희입니다. 귀사의 제품에 대한 관심이 많아 다음 주 수요일 오전 10시에 제품 시연 회의를 요청드리고자 합니다.

이 회의에서 귀사의 최신 제품에 대해 자세히 알아보고, 협력 가능성을 논의하고 싶습니다. 회신은 금요일까지 부탁드리겠습니다.

감사합니다.

이영희 드림
영업 팀장
XYZ 주식회사
010-1234-5678

---

**보내기 링크**:
<a href="mailto:chulsoo.kim@abc.com?subject=제품%20시연%20회의%20요청&body=안녕하세요%2C%20김철수%20이사님.%0A%0A저는%20XYZ%20주식회사%20영업%20팀장%20이영희입니다.%20귀사의%20제품에%20대한%20관심이%20많아%20다음%20주%20수요일%20오전%2010시에%20제품%20시연%20회의를%20요청드리고자%20합니다.%0A%0A이%20회의에서%20귀사의%20최신%20제품에%20대해%20자세히%20알아보고%2C%20협력%20가능성을%20논의하고%20싶습니다.%20회신은%20금요일까지%20부탁드리겠습니다.%0A%0A감사합니다.%0A%0A이영희%20드림%0A영업%20팀장%0AXYZ%20주식회사%0A010-1234-5678">보내기</a>

---

위와 같이 단계별로 필요한 정보를 입력하시면 됩니다! 도움이 필요하시면 언제든지 말씀해주세요!`,
          isUser: 'system',
        },
      ]);
    } else {
      setMessages([]);
    }
  }, [chatType]);

  const sendMessageToApi = async (messageText: string) => {
    setIsLoading(true);
    setLoad(true);

    const baseApiEndpoint = 'https://chat-api.heartyworks.com/';
    let apiPath = '';
    let data = {};

    const clientId = localStorage.getItem('uuid');

    switch (chatType) {
      case 'business':
        apiPath = 'chat-unification';
        data = { userInput: messageText, clientId };
        break;
      case 'mail':
        apiPath = 'chat-mail';

        // 메일일 경우 메시지 기록에 추가
        const newMessages = [
          ...messages,
          {
            id: messages.length + 1,
            text: messageText,
            isUser: true, // 여기서 메시지가 추가됨
          },
        ];

        data = {
          userInput: messageText,
          model: 'gpt-4o',
          temperature: 0.5,
          max_tokens: 2048,
          messages: [
            ...newMessages.map((msg) => ({
              role:
                msg.isUser === 'system'
                  ? 'system'
                  : msg.isUser
                  ? 'user'
                  : 'assistant',
              content: msg.text, // 백엔드 전송 시 개행 유지
            })),
          ],
        };
        break;
      case 'foreign':
        apiPath = 'chat-foreign';
        data = { userInput: messageText, clientId };
        break;
      case 'korea-lan':
        apiPath = 'chat-korea';
        data = { userInput: messageText, clientId };
        break;
      case 'korea':
        apiPath = 'chat-korean';
        data = { userInput: messageText, clientId };
        break;
      default:
        break;
    }

    const apiEndpoint = `${baseApiEndpoint}${apiPath}`;

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
      });

      let botResponse: any;
      if (chatType === 'mail') {
        botResponse = await response.text();
      } else {
        botResponse = await response.json();
      }

      // 중복된 메시지 추가를 방지하기 위해 이 부분을 수정합니다.
      if (chatType === 'mail') {
        setMessages((prevMessages) => {
          const newMessages = [
            ...prevMessages,
            {
              id: prevMessages.length + 2, // ID를 2만큼 증가시켜 중복을 방지합니다.
              text: formatMessageForDisplay(botResponse), // 봇 응답
              isUser: false,
            },
          ];
          playSound(receiveSoundFile);

          return newMessages;
        });
      } else {
        addMessage(botResponse.text.value, false);
      }
    } catch (error) {
      console.log(error);
      addMessage('오류가 발생했습니다. 다시 시도해주세요.', false);
    } finally {
      setLoad(false);
      setIsLoading(false);
    }
  };

  const formatMessageForDisplay = (text: string) => {
    // 사용자에게 보여줄 메시지의 개행 처리
    return text.replace(/\\n/g, '\n');
  };

  const addMessage = (text: string, isUser: boolean | 'system') => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: prevMessages.length + 1, text, isUser },
    ]);

    if (isUser === true) {
      playSound(sendSoundFile);
    } else if (isUser === false) {
      playSound(receiveSoundFile);
    }
  };

  return { messages, addMessage, sendMessageToApi, load };
}
