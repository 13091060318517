import { MessageListProps } from 'Message';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const formatText = (text: string | undefined): JSX.Element | null => {
if (!text) return null;

// 이스케이프된 따옴표를 실제 따옴표로 변환
const processedText = text.replace(/\\(['"])/g, '$1');

// mailto 링크를 식별하는 정규식
const mailtoRegex = /<a\s+href="mailto:([^"]+)">([^<]+)<\/a>/g;
const elements: React.ReactNode[] = [];
let lastIndex = 0;
let match;

while ((match = mailtoRegex.exec(processedText)) !== null) {
const [fullMatch, href, displayText] = match;
const { index } = match;

// mailto 링크 이전의 텍스트 처리
const precedingText = processedText.substring(lastIndex, index);
precedingText.split('\n').forEach((line, idx) => {
elements.push(
<React.Fragment key={`text-${lastIndex}-${idx}`}>
{line}
<br />
</React.Fragment>
);
});

// mailto 링크 추가
elements.push(
<a key={`link-${index}`} href={`mailto:${href}`}>
{displayText}
</a>
);

lastIndex = index + fullMatch.length;
}

// 남은 텍스트 처리
const remainingText = processedText.substring(lastIndex);
remainingText.split('\n').forEach((line, idx) => {
elements.push(
<React.Fragment key={`text-${lastIndex + idx}`}>
{line}
<br />
</React.Fragment>
);
});

return <span>{elements}</span>;
};

function Message({ messages }: MessageListProps) {
const chatContainerRef = useRef<HTMLDivElement>(null);

useEffect(() => {
if (chatContainerRef.current) {
chatContainerRef.current.scrollTop =
chatContainerRef.current.scrollHeight;
}
}, [messages]);

return (
<ChatContainer ref={chatContainerRef}>
{messages.map((message) =>
message.isUser && message.isUser !== 'system' ? (
<UserMessageBubble key={message.id} isUser={true}>
{formatText(message.text)}
<br />
</UserMessageBubble>
) : (
<BotMessageBubble key={message.id} isUser={false}>
{formatText(message.text)}
<br />
<br />
</BotMessageBubble>
)
)}
</ChatContainer>
);
}

export default React.memo(Message);

const ChatContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-end;
width: 640px;
height: auto;
max-height: 700px;
scrollbar-width: none;
-ms-overflow-style: none;
overflow-y: scroll;

&::-webkit-scrollbar {
display: none;
}
@media (max-width: 768px) {
width: 340px;
}
`;

interface MessageBubbleProps {
isUser: boolean;
}

const UserMessageBubble = styled.h4<MessageBubbleProps>`
max-width: 58%;
padding: 10px 20px;
border-radius: 20px;
margin-bottom: 10px;
font-weight: 350;
word-break: keep-all;
background-color: ${(props) => (props.isUser ? '#2a2a2a' : '#3a3a3a')};
color: white;
align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
user-select: text;

@media (max-width: 768px) {
padding: 15px 25px;
max-width: 90%;
font-size: 16px;
}
`;

const BotMessageBubble = styled.div<MessageBubbleProps>`
max-width: 58%;
padding: 10px 20px;
border-radius: 20px;
margin-bottom: 10px;
word-break: keep-all;
background-color: ${(props) => (props.isUser ? '#2a2a2a' : '#3a3a3a')};
color: white;
align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
user-select: text;

@media (max-width: 768px) {
padding: 15px 25px;
max-width: 90%;
font-size: 16px;
}
`;